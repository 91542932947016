const roleMapping = {
  omt_admin: 'OMT Admin',
  omms_admin: 'OMMS Admin',
  omt_tacct_admin: 'TACCT Admin',
  omms_metadata_manager: 'OMMS Metadata Manager',
  omms_peer_review_bia: 'OMMS BIA Team',
  omms_peer_review_campaign: 'OMMS Campaign Team',
  omms_read_only: 'OMMS Read Only',
  omms_vendor: 'OMMS Vendor',
  omms_peer_review_channel: 'OMMS Channel Team',
  omms_list_management: 'List Management',
  omt_tacct_bl_approver_taxccb: 'TACCT Approver TaxCCB',
  omt_tacct_bl_metadata_owner: 'TACCT Metadata Owner',
  omt_tacct_bl_approver_omni: 'TACCT Approver Omni',
  omt_tacct_bl_read: 'TACCT Read Only',
  omt_tacct_bl_metadata_requester: 'TACCT Metadata Requester',
  omt_list_management_admin: 'List Management Admin',
  omt_response_matrix: 'Response Matrix',
  omt_cap_audit_tool_read_only: 'CAP Data Team'
};

export default roleMapping;